import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Superscript from '../components/Superscript'
import { rhythm, scale } from '../utils/typography'

const shadow = `1px 1px #777, 2px 2px #777, 3px 3px #777, 4px 4px #777, 5px 5px #777,
6px 6px #ccc, 7px 7px #ccc, 8px 8px #ccc, 9px 9px #ccc, 10px 10px #ccc`

const mobileshadow = `0.1vw 0.1vw #777, 0.2vw 0.2vw #777, 0.3vw 0.3vw #777, 0.4vw 0.4vw #777, 0.5vw 0.5vw #777,
0.6vw 0.6vw #ccc, 0.7vw 0.7vw #ccc, 0.8vw 0.8vw #ccc, 0.9vw 0.9vw #ccc, 1vw 1vw #ccc`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          ogImage={
            (post.frontmatter.ogImage &&
              post.frontmatter.ogImage.childImageSharp.original.src) ||
            null
          }
          twitterImage={
            (post.frontmatter.twitterImage &&
              post.frontmatter.twitterImage.childImageSharp.original.src) ||
            null
          }
        />
        <Superscript
          shadow={shadow}
          mobileshadow={mobileshadow}
          fontSize="100px"
          mobileSize="10vw"
          style={{
            lineHeight: 1,
            color: '#333',
          }}
        >
          {post.frontmatter.title}
        </Superscript>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        />
        <Content>
          <Small>PUBLISHED ON {post.frontmatter.date.toUpperCase()}</Small>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Content>
        <hr />
        <form
          style={{
            marginBottom: '42px',
            // border: '1px solid #ccc',
            // padding: '3px',
            // textAlign: 'center',
          }}
          action="https://tinyletter.com/karldanninger"
          method="post"
          target="popupwindow"
          onsubmit="window.open('https://tinyletter.com/karldanninger', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
        >
          <label htmlFor="tlemail">
            <h3>Let's be friends</h3>
            <p>
              Get occasional emails from me that I'm hoping will start
              conversations.
            </p>
          </label>

          <p>
            <NewsletterInput
              type="text"
              name="email"
              id="tlemail"
              placeholder="hello@friend.com"
            />
          </p>
          <input type="hidden" value="1" name="embed" />
          <input
            type="submit"
            value="Subscribe"
            style={{
              backgroundColor: 'blue',
              color: 'white',
              padding: '8px 24px',
              fontFamily: 'Inter',
              border: 'none',
              cursor: 'pointer',
            }}
          />
        </form>

        <hr />
        {/* TODO: style previous and next links
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`writing` + previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`writing` + next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        */}
        <footer style={{ marginTop: 42 }}>
          <Link to="/">
            {' '}
            <svg
              id="Layer_1"
              style={{ width: 50, fill: '#333' }}
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 915.32 919.29"
            >
              <path
                className="cls-1"
                d="M294 430.35l-141.06-1.96L0 581.33l335.81 7.85v330.11l153.67-153.67V626.17l68.3 68.3 109.56-109.56-333.67-337.23-111.35 112.06L294 430.35z"
              />
              <path
                className="cls-1"
                d="M1587 919.76h-1c-140.87-145.13-264.8-80.31-334.56-10.55l-156.78 156.37L1431 1403.34l159.56-161c69.75-69.73 119.04-199.96-3.56-322.58zm-183.78 276.68l-98.6-99 69.28-70.44c49-45.9 93.88 9 93.88 9 48.87 48.58 6.31 90.75 6.31 90.75z"
                transform="translate(-746.57 -832.66)"
              />
            </svg>
          </Link>
        </footer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

const NewsletterInput = styled.input`
  width: 50%;
  border: none;
  padding: 8px 24px;
  background-color: #eee;
  @media (max-width: 680px) {
    width: 100%;
  }
`

const Content = styled.div`
  font-family: Inter, sans-serif;
  margin-left: 25%;
  padding-top: 50px;
  a {
    color: blue;
  }
  .gatsby-resp-image-link {
    pointer-events: none !important;
  }
  .gatsby-resp-image-wrapper {
    max-width: initial !important;
    pointer-events: none !important;
    margin-left: -33.333% !important;
    @media (max-width: 680px) {
      margin-left: initial !important;
    }
  }
  strong {
    font-weight: 900;
  }
  h5 {
    font-size 32px;
    margin-bottom: 8px;
  }
  @media (max-width: 680px) {
    margin-left: 0;
  }
`

const Small = styled.div`
  font-size: 10px;
  letter-spacing: 1px;
  color: rgb(141, 141, 141);
  font-family: Inter, sans-serif;
  sup {
    font-size: 12px !important;
    top: 0;
  }
`

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        ogImage {
          childImageSharp {
            original {
              src
            }
          }
        }
        twitterImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`
